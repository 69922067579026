<template>
    <div class="pd20x">
        <a-modal
            title="增加使用次数"
            :visible="visible"
            @cancel="visible = false; addTime = undefined"
            centered
            destroyOnClose
            :footer="null"
            width="400"
            >
            <div class="taC">
                <a-space>
                    <a-input v-model="addTime"  placeholder="请输入增加的使用次数" />
                    <a-button type="primary" @click="handleTime">确定</a-button>
                </a-space>
            </div>
        </a-modal>
        <div class="mb20x">
            <a-button type="primary" class="mr20px" @click="$router.push('/exam/students')"><a-icon type="left" />返回</a-button>
            <a-button type="primary" @click="showCate()">添加试卷</a-button>
        </div>
        <a-table
          :columns="columns"
          :data-source="list"
          :rowKey="'id'"
          bordered
          class="normal"
        >
            <template slot="time" slot-scope="text">
                {{ text + '元'}}
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-icon type="plus-circle" title="增加次数" class="plusIcon" @click="showTime(record.id)"/>
            </template>
        </a-table>
        <a-modal v-model="show" title="选择赠送试卷" width="800px" @ok="submit" @cancel="show = false">
            <div class="limit">
                <h3 class="title">
                    <span class="el-tag"></span>选择套卷
                </h3>
            </div>
            <div class="ml20x mb20x">
                <a-cascader ref="cascader" v-model="selectSubject" @change="changeSubject"  style="width:80%" :options="subjectList" :allowClear="false" :fieldNames="{label:'name',value:'id', children:'son'}" placeholder="请选择套卷"/>
            </div>
            <div class="limit">
                <h3 class="title">
                    <span class="el-tag"></span>试卷
                </h3>
            </div>
            <div class="ml20x mb20x">
                 <a-spin :indicator="indicator" tip="请先选择套卷" :spinning="!selectSubject.length">
                    <a-table
                        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                        :columns="subjectColumns"
                        :data-source="data"
                        :rowKey="'id'"
                        :pagination="false"
                    >
                        <template slot="time" slot-scope="text">
                          {{ text }} 元
                        </template>
                    </a-table>
                 </a-spin>
            </div>
        </a-modal>
    </div>
</template>
<script>
const columns = [
    { title: '试卷名称', dataIndex: 'name', align: 'center' },
    { title: '试卷原价', dataIndex: 'cost_price', align: 'center' },
    { title: '试卷现价', dataIndex: 'now_price', align: 'center' },
    { title: '剩余次数', dataIndex: 'nums', align: 'center' },
    { title: '状态', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align: 'center' }
]
const subjectColumns =[
    { title: '试卷名称', dataIndex: 'name', align: 'center' },
    { title: '原价', dataIndex: 'cost_price', align: 'center',scopedSlots: { customRender: 'time' } },
    { title: '现价', dataIndex: 'now_price', align: 'center',scopedSlots: { customRender: 'time' } },
]

import { classB } from '@/libs/transform'
import { getclasses } from '@/libs/api'
import { addPaperNums, getUserPapers, getGoodTask, sendPapers } from '@/libs/examapi'
import {momentDays, formatDay} from '@/libs/moment';
export default {
    name: 'courseLimit',
    data(){
        return{
            //表格
            columns,
            list: [],


            classB,
            subjectList:[],
            platSubjectList:[],
            show: false,
            data: [],
            checkedBoard: [1,2,3,4],
            currentItem:{},
            checkedList:[],

            selectSubject:[],
            subjectColumns,
            selectedRowKeys: [],
            spinning:true,
            indicator: null,
            uid: null,
            subStatus: false,
            addId: 0,
            visible: false,
            addTime: undefined
        }
    },

    mounted(){
        this.uid = this.$route.query.id
        this.init()
    },
    methods:{
        formatDay,
        disabledDate(current) {
            return current && current < momentDays().endOf('day');
        },
        handleOk(){
            this.show = false
        },
        init(){
            getclasses({id: 0}).then(res=>{
               this.subjectList = res
               this.platData(res)
            },error=>{
            })
            this.getData()
        },
        platData(data){
            data.forEach(el =>{
                this.platSubjectList.push(el)
                if(el.son.length){
                    this.platData(el.son)
                }
            })
        },
        getData(){
          getUserPapers({uid: this.uid}).then(res =>{
                this.list = res
            })
        },
        onClose(){
            this.$emit('close')
        },
        changeTime(val, id){
           this.data.forEach(el =>{
               if(el.id == 4){
                   el.time = formatDay(val)
               }else{
                    if(el.id === id){
                        el.time = val
                    }
               }

           })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        submit(){

            if(this.selectedRowKeys.length){
                if(this.subStatus)
                  return false;
                this.subStatus = true;
                this.updateData()
            }else{
                this.show = false
            }
        },
        updateData(){
          console.log(this.selectedRowKeys)
          sendPapers({ids: this.selectedRowKeys.join(',') , uid: this.uid}).then(res => {
            this.show = false
            this.getData()
          }).catch(() =>{
            this.show = false
          }).finally(() => {
            this.subStatus = false
          })
        },
        showCate(data){
            this.show = true
            if(data){
                let { cate_id, type_1_end, type_2_end, type_3_end, type_4_end } = data
                this.searchCateId(cate_id)
                this.data = []
            }else{
                this.selectSubject = []
                this.data = []
            }
        },
        searchCateId(id){
            this.selectSubject.unshift(id)
            let {parent_id} = this.platSubjectList.filter(el => el.id == id)[0]
            let selected = this.platSubjectList.filter(el => el.id == parent_id)[0]
            if(selected.level>0){
                this.searchCateId(selected.id)
            }else{
                this.selectSubject.unshift(selected.id)
            }
        },
        changeSubject(val){
            let cate_id = [...val].pop()
            getGoodTask({cate_id}).then(res =>{
                this.data = res
            })
        },
        showTime(id){
            this.addId = id
            this.visible = true
        },
        handleTime(){
          addPaperNums({id:this.addId,nums:this.addTime,uid:this.uid}).then(res =>{
            this.visible = false
            this.getData()
          })
        }
    }
}
</script>
<style lang="less" scoped>
.limit{
    .title{
        font-size: 16px;
        color: #333;
        font-weight: 400;
        line-height: 18px;
        display: flex;
        margin-bottom: 16px
    }
    .el-tag{
        padding: 0 1px;
        height: 18px;
        margin-right: 10px;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        border-color: #409eff;
        background: #409eff;
        box-sizing: border-box
    }
}
.ml10{
    margin-left: 10px;
}
.tags{
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    display: inline-block;
    padding: 0 10px;
    font-size: 12px;
    color: #333;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    margin: 0 4px 16px 14px;
    height: 28px;
    line-height: 26px;
}
.tags.is-hit{
    border-color: #409eff;
    background-color: #409eff;
    color: #fff;
}
.plusIcon{
    margin-left: 10px;
    font-size: 20px;
    color: #409eff;
    vertical-align: middle;
    cursor: pointer;
}
</style>
